interface TwoBannersProps {
  banners: Array<{
    image: string
    title: string
    description: string
    link: string
  }>
}

const TwoBanners = ({ banners }: TwoBannersProps) => {
  return (
    <div className="two-banners-container">
      {banners?.map((banner, index) => {
        return (
          <div key={index} className="banner-wrapper">
            <img src={banner.image} alt={`${banner.description} | Decathlon`} />
            <div className="cta-banner">
              <h2>{banner.title}</h2>
              <p>{banner.description}</p>
              <a href={banner.link}>confira</a>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TwoBanners
